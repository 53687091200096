import React from 'react'

import articlePicture from '../../assets/img/articles/earth-hour.jpg'

const article = {
  slug: 'earth-hour',
  name: 'Earth Hour : dans le monde entier, des villes éteignent leurs lumières pour la planète',
  description: 'L\'Opéra de Sydney, le Colisée de Rome ou encore la tour Eiffel ont été plongés dans le noir pour "l\'heure de la planète", une opération organisée par l\'ONG WWF.',
  content: <>
    <p>
    Des villes du monde entier ont éteint la lumière de leurs monuments pendant une heure, samedi 27 mars, pour l'Earth Hour ("l'heure pour la planète" en français). L'évènement, organisée par l'ONG WWF, est destinée à alerter sur le changement climatique et la protection de l'environnement.
    </p>
    <p>
    Les lumières des gratte-ciels des métropoles asiatiques, de Singapour à Hong Kong, qui se sont éteintes à 20h30 heure locale, pendant une heure, de même que celles de monuments tels que l'Opéra de Sydney. En Europe, le Colisée de Rome, la place Rouge de Moscou, la Porte de Brandebourg de Berlin ont été successivement plongés dans l'obscurité.
    </p>
    <p>
    A Paris, c'est la tour Eiffel qui s'est retrouvée sans lumière, même si couvre-feu oblige, bien peu ont pu en profiter. Cette année, les organisateurs ont voulu mettre en évidence le lien entre la destruction de la nature et l'incidence croissante des maladies comme le Covid-19.
    </p>
    <p>
    © 2021 AFP et image par STEPHANE DE SAKUTIN / AFP
    </p>
  </>,
  category: 'Autres',
  date: 'Mars 2021',
  image: articlePicture
}

export default article